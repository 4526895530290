import { StoreTypes } from '~/composables/types/storeTypes'

export default function () {
    const { $isStore } = useNuxtApp()
    const isVoStore = () => $isStore(StoreTypes.VO)
    const isNoStore = () => $isStore(StoreTypes.NO)

    return {
        isVoStore,
        isNoStore,
    }
}
